import { Icon } from '/features/buildingBlocks/Icon'

import styles from './LinkIcon.css'

export function LinkIcon({ title, href, icon }) {
  const protocols = ['http', 'https', 'tel', 'mailto']
  const hasProtocol = protocols.some(x => href?.startsWith(x))
  const target = hasProtocol ? '_blank' : '_self'
  const rel = hasProtocol ? 'noopener noreferrer' : null

  return (
    <a
      className={styles.component}
      aria-label={title}
      data-x="link"
      {...{ href, rel, target }}
    >
      <span className={styles.iconContainer}>
        <Icon layoutClassName={styles.icon} {...{ icon }} />
      </span>
      {title}
    </a>
  )
}
