import { useWasInViewport } from '@kaliber/use-is-in-viewport'
import { useScrollProgression, triggers } from '@kaliber/scroll-progression'
import { lerp } from '@kaliber/math'
import { useSpring, animated } from '@react-spring/web'
import { easeOutQuad } from '/machinery/easings'

import { HeadingMd } from '/features/buildingBlocks/Heading'
import { ImageCover } from '/features/buildingBlocks/Image'
import { useTranslate } from '/machinery/I18n'
import { ContainerMd } from '/features/buildingBlocks/Container'
import { LinkIcon } from '/features/buildingBlocks/LinkIcon'
import iconArrow from '/images/icons/arrow-right.raw.svg'

import styles from './CtaNextInitiative.css'

export function CtaNextInitiative({ image, title, href }) {
  const { __ } = useTranslate()
  const { ref: elementRef, wasInViewport } = useWasInViewport({ threshold: [1] })
  const [{ scale }, scaleSpring] = useSpring(() => ({ scale: 1.1, config: { tension: 100, friction: 35 } }))
  const [{ clip }, clipSpring] = useSpring(() => ({ clip: 0, config: { tension: 100, friction: 55 } }))
  const [{ opacity }, opacitySpring] = useSpring(() => ({ opacity: 0 }))

  const imageRef = useScrollProgression({
    start: { element: triggers.top(), scrollParent: triggers.bottom() },
    end: { element: triggers.top(), scrollParent: triggers.custom(0.5) },
    onChange(progression) {
      scaleSpring.start({ scale: lerp({ start: 1.1, end: 1, input: easeOutQuad(progression) }) })
      opacitySpring.start({ opacity: lerp({ start: 0.4, end: 0.8, input: easeOutQuad(progression) }) })
      clipSpring.start({ clip: progression })
    }
  })

  const springConfig = {
    to: {
      opacity: wasInViewport ? 1 : 0,
      transform: wasInViewport ? 'translateY(0)' : 'translateY(40px)'
    },
    config: { tension: 100, friction: 35 }
  }

  const headingSpring = useSpring({ ...springConfig })
  const linkAnim = useSpring({ ...springConfig, delay: 100 })

  return (
    <figure className={styles.component}>
      <figcaption className={styles.content}>
        <ContainerMd>
          <div className={styles.inner} ref={elementRef}>
            {title && (
              <animated.div className={styles.heading} style={headingSpring}>
                <HeadingMd h='2'>{title}</HeadingMd>
              </animated.div>
            )}
            {href && <animated.div style={linkAnim}><LinkIcon icon={iconArrow} {... { href }} title={__`discover-more-of-our-work`} /></animated.div>}
          </div>
        </ContainerMd>
      </figcaption>
      <animated.div className={styles.clip} style={{
        clipPath: clip.to(x => {
          const pos = lerp({ start: 80, end: 100, input: x })
          const neg = lerp({ start: 20, end: 0, input: x })
          return `polygon(${neg}% ${neg}%, ${pos}% ${neg}%, ${pos}% ${pos}%, ${neg}% ${pos}%)`
        })
      }}>
        <animated.div className={styles.imageContainer} ref={imageRef} style={{ opacity, scale }}>
          {image && <ImageCover layoutClassName={cx(styles.image, styles.desktopVersion)} imgProps={{ loading: 'lazy' }} {... { image }} aspectRatio={16 / 9} />}
          {image && <ImageCover layoutClassName={cx(styles.image, styles.mobileVersion)} imgProps={{ loading: 'lazy' }} {... { image }} aspectRatio={9 / 16} />}
        </animated.div>
      </animated.div>
      <div className={styles.gradient} role="presentation" />
    </figure>
  )
}
